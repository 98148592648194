import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/scss/main.scss';
import { createPinia } from "pinia";
import Popper from "vue3-popper"

createApp(App)
    .use(router)
    .use(createPinia())
    .component("Popper", Popper)
    .mount('#app');
