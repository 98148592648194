import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { defineAsyncComponent } from 'vue';
import { useUserStore } from "@/stores/user";
import { setAuthorization } from '@/http';
import spaceMountainAPI from "@/api/spaceMountainAPI";
import {AxiosResponse} from "axios";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: { name: 'Home' }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import('@/views/Login.vue'),
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import('@/views/Signup.vue'),
  },
  {
    path: "/home",
    name: "Home",
    component: () => import('@/views/Home.vue'),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import('@/views/Profile.vue'),
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () => import('@/views/Notifications.vue'),
  },
  {
    path: "/folders",
    name: "Folders",
    props: true,
    component: () => import('@/views/Folders.vue'),
  },
  {
    path: "/users",
    name: "Users",
    props: true,
    component: () => import('@/views/Users.vue'),
  },
  {
    path: "/user",
    name: "User",
    props: true,
    component: () => import('@/views/User.vue'),
  },
  {
    path: "/oldfolders",
    name: "OldFolders",
    component: () => import('@/views/OldFolders.vue'),
  },
  {
    path: "/folder/:folderId",
    name: "CurrentFolder",
    component: () => import('@/views/CurrentFolder.vue'),
  },
  {
    path: "/folder/:folderId/forms",
    name: "Forms",
    component: () => import('@/views/Forms.vue'),
  },
  {
    path: "/folder/:folderId/files",
    name: "Files",
    component: () => import('@/views/Files.vue'),
  },
  {
    path: "/folder/:folderId/users",
    name: "FolderUsers",
    component: () => import('@/views/Users.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  //BY HUB
  let redirectPath = 'login';
  if (to.query.hasOwnProperty('token') && to.name === 'Home') {
    setAuthorization(to.query.token);
    await userStore.check().then(async (response: AxiosResponse) => {
      if (response.status !== 401) {
        await userStore.loadUserInfo().then(async (response: AxiosResponse) => {
          userStore.fillInfos(response.data, response.data.preferences, to.query.token);
          redirectPath = 'home';
        });
      }
    });
    next(redirectPath);
  }
  //BY LOGIN
  else {
    const auth = localStorage.getItem('sqg-auth');
    if (to.name === 'Login' || to.name === 'Signup') {
      if (!userStore.isLogged) next();
      else {
        userStore.logout();
        next();
      }
    } else {
      if (auth === null) next('login');
      if (from.name !== 'Login') {
        await userStore.check().then(async (response: AxiosResponse) => {
          if (response.status !== 200) {
            next('login');
          } else {
            if (!userStore.isLogged) await userStore.loadUserInfo().then(() => {
              next();
            });
            next();
          }
        });
      } else next();
    }
  }
});

export default router;
