
import {defineAsyncComponent, defineComponent, onMounted} from "vue";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: 'App',
  components: {
    Sidenav: defineAsyncComponent(() => import('@/components/Sidenav.vue')),
  },
  setup() {
    const userStore = useUserStore();
    const { isLogged, randomColor } = storeToRefs(userStore);

    return { isLogged, randomColor };
  },
})
