import { HTTP } from '@/http';
import { AxiosResponse } from "axios";
import {sleep} from "@/functions/utils";

export default {

    getFolders() {
        return HTTP.get(`/admin/folders`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    getFolderById(folderId: string) {
        return HTTP.get(`/admin/folder/${folderId}`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    getUsersByKey(key: string) {
        return HTTP.post(`/admin/contact?email=${key}`, )
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    getUserById(userId: string) {
        return HTTP.get(`/admin/user/${userId}`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    getAllUsers() {
        return HTTP.post(`/admin/users`, )
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    getForm(formId: string | string[]) {
        return HTTP.get(`/admin/form/${formId}`)
            .then(async (response: AxiosResponse) => {

                return response;
            }).catch((response) => {
                return response;
            });
    },

    createAdmin(firstname: string, lastname: string, email: string, password: string) {
        return HTTP.post(`/admin/create`, { firstname: firstname, lastname: lastname, email: email, password: password })
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    nextStep(folderId: string) {
        return HTTP.post(`/admin/folderToNextStep`, { folderId: folderId })
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    previousStep(folderId: string) {
        return HTTP.post(`/admin/folderToPreviousStep`, { folderId: folderId })
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    cancelFolder(folderId: string) {
        return HTTP.post(`/admin/cancelFolder`, { folderId: folderId })
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    uploadFile(contentB64: string, fileName: string, relatedFolder: string, relatedFormId: string) {
        return HTTP.post(`/admin/linkedFile`, { contentB64: contentB64, fileName: fileName, fileType: 'dsn', relatedFolder: relatedFolder, relatedFormId: relatedFormId, creationDate: 0 })
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    deleteFile(fileId: string) {
        return HTTP.delete(`/admin/linkedFile/${fileId}`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    downloadFile(fileId: string) {
        return HTTP.get(`/admin/linkedFileB64/${fileId}`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    deleteUser(userId: string) {
        return HTTP.post(`/admin/deleteUser`, { userId: userId })
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    updateAdmin(firstname: string, lastname: string) {
        return HTTP.post(`/admin/update`, { firstname: firstname, lastname: lastname })
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    follow(folderId: string) {
        return HTTP.post(`/admin/folder/follow`, { folderId: folderId })
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    unfollow(folderId: string) {
        return HTTP.delete(`/admin/folder/follow/${folderId}`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    getResponsables() {
        return HTTP.get(`/admin/users/responsable`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    changeLogin(userId: string, email: string, password: string) {
        return HTTP.post(`/admin/change-credentials`, {userId: userId, newEmail: email, newPassword: password})
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },
};
