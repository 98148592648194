import { defineStore, storeToRefs } from "pinia";
import api from "@/api/spaceMountainAPI";
import Folder from "@/types/Folder";
import adminApi from "@/api/spaceMountainAdminAPI";
import { useUserStore } from "@/stores/user";
import { AxiosResponse } from "axios";

export const useFoldersStore = defineStore({

    id: "folderStore",
    state: () => ({
        folders: [] as Array<Folder>,
        currentFolder: {
            name: '',
            state: '',
            id: '',
            creationDate: '',
            customerCompany: '',
            files: [],
            forms: [],
            followers: [],
        } as Folder,
        folderLoader: false,
        folderState: 0,
    }),

    actions: {
        redirectApi() {
            const userStore = useUserStore();
            if (userStore.user.role === 'admin' || userStore.user.role === 'superAdmin') {
                return adminApi;
            } else return api;
        },

        goLoader() {
            this.folderState++;
            if (this.folderState > 0) this.folderLoader = true;
        },

        stopLoader() {
            this.folderState--;
            if (this.folderState === 0) this.folderLoader = false;
        },

        setCurrentFolder(folder: Folder) {
            this.currentFolder = folder;
            return true;
        },

        getFolders() {
            this.goLoader();
            return this.redirectApi().getFolders().then((response: AxiosResponse) => {
                this.folders = response.data;
                this.stopLoader();
                return response;
            });
        },

        getFolderById(id: string) {
            this.goLoader();
            return this.redirectApi().getFolderById(id).then((response: AxiosResponse) => {
                this.currentFolder = response.data;
                this.stopLoader();
                return response;
            });
        },

        createFolder(folderName: string, responsableId: string | undefined) {
            this.goLoader();
            return api.createFolder(folderName, responsableId).then((response: AxiosResponse) => {
                this.stopLoader();
                return response;
            });
        },

        validateFolder(folderId: string) {
            this.goLoader();
            return api.validateFolder(folderId).then((response: AxiosResponse) => {
                this.stopLoader();
                return response;
            });
        },

        getFoldersByUserId(userId: string) {
            this.goLoader();
            return api.getFoldersByUser(userId).then((response: AxiosResponse) => {
                this.stopLoader();
                return response;
            });
        },

        nextStep(folderId: string) {
            this.goLoader();
            return adminApi.nextStep(folderId).then((response: AxiosResponse) => {
                const userStore = useUserStore();
                userStore.updatePreferences(folderId);
                this.stopLoader();
                return response;
            });
        },

        previousStep(folderId: string) {
            this.goLoader();
            return adminApi.previousStep(folderId).then((response: AxiosResponse) => {
                const userStore = useUserStore();
                userStore.updatePreferences(folderId);
                this.stopLoader();
                return response;
            });
        },

        cancelFolder(folderId: string) {
            this.goLoader();
            return adminApi.cancelFolder(folderId).then((response: AxiosResponse) => {
                this.stopLoader();
                return response;
            });
        },

        uploadFile(contentB64: string, fileName: string, relatedFolder: string, relatedFormId: string) {
            this.goLoader();
            return this.redirectApi().uploadFile(contentB64, fileName, relatedFolder, relatedFormId).then((response: AxiosResponse) => {
                const userStore = useUserStore();
                userStore.updatePreferences(relatedFolder);
                this.stopLoader();
                return response;
            });
        },

        deleteFile(fileId: string) {
            this.goLoader();
            return this.redirectApi().deleteFile(fileId).then((response: AxiosResponse) => {
                this.getFolderById(this.currentFolder.id);
                this.stopLoader();
                return response;
            });
        },

        submitForms(folderId: string) {
            this.goLoader();
            return api.submitForms(folderId).then((response: AxiosResponse) => {
                const userStore = useUserStore();
                userStore.updatePreferences(folderId);
                this.stopLoader();
                return response;
            });
        },

        addComment(folderId: string, content: string) {
            this.goLoader();
            return api.addComment(folderId, content).then((response: AxiosResponse) => {
                this.stopLoader();
                return response;
            });
        },

        follow(folderId: string) {
            this.goLoader();
            return adminApi.follow(folderId).then((response: AxiosResponse) => {
                this.stopLoader();
                return response;
            });
        },

        unfollow(folderId: string) {
            this.goLoader();
            return adminApi.unfollow(folderId).then((response: AxiosResponse) => {
                this.stopLoader();
                return response;
            });
        },
    },
});
