import {clearAuthorization, HTTP, setAuthorization} from '@/http';
import { AxiosResponse } from "axios";
import { sleep } from '@/functions/utils';

export default {
    check() {
        const auth = localStorage.getItem('sqg-auth');
        if (auth != null) {
            setAuthorization(JSON.parse(auth).token);
        }
        return HTTP.get(`/authentication/check`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch(() => {
                clearAuthorization();
                return <AxiosResponse>{ status: 401 };
            });
    },

    signup(user:Object) {
        return HTTP.post(`/user/customer`, user)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
        });
    },

    login(email: string, password: string) {
        return HTTP.post(`/authentication/customer`, {email: email, password: password})
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    getUserById(userId: string) {
        return HTTP.get(`/user/${userId}`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    getUserByToken() {
        return HTTP.get(`/user/getUserByToken`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    getFolders() {
        return HTTP.get(`/folders`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    getFolderById(folderId: String) {
        return HTTP.get(`/folder/${folderId}`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    createFolder(folderName: string, responsableId: string | undefined) {
        return HTTP.post(`/folder`, { name: folderName, responsableId: responsableId })
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    validateFolder(folderId: string) {
        return HTTP.post(`/folder/validate`, { folderId: folderId })
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    setPreferences(preferences: string) {
        return HTTP.put(`/user/preferences`, { preferences: `${preferences}` })
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    resetPreferences() {
        return this.setPreferences("{\"favoriteFolders\":[],\"oldFolders\":[],\"notifications\":[],\"currentFolderId\":\"\"}");
    },

    getForm(formId: string | string[]) {
        return HTTP.get(`/montage/form/${formId}`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    saveForm(formId: string | string[], form: Object) {
        return HTTP.put(`/montage/form/draft`, { id: formId, value: form })
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    submitForm(formId: string | string[], form: Object) {
        return HTTP.put(`/montage/form/save`, { id: formId, value: form })
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    submitForms(folderId: string) {
        return HTTP.post(`/folder/endConfiguration`, { folderId: folderId })
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    uploadFile(contentB64: string, fileName: string, relatedFolder: string, relatedFormId: string) {
        return HTTP.post(`/linkedFile`, { contentB64: contentB64, fileName: fileName, fileType: 'dsn', relatedFolder: relatedFolder, relatedFormId: relatedFormId, creationDate: 0 })
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    deleteFile(fileId: string) {
        return HTTP.delete(`/linkedFile/${fileId}`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    downloadFile(fileId: string) {
        return HTTP.get(`/linkedFileB64/${fileId}`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    getAllUsers() {
        return HTTP.get(`/user/users`, )
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    changePassword(password: string) {
        return HTTP.post(`/user/change-credentials`, { password: password })
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    createUser(firstname: string, lastname: string, email: string, password: string, responsableId: string | undefined) {
        return HTTP.post(`/user/gestionnaire`, { firstname: firstname, lastname: lastname, email: email, password: password, responsableId: responsableId })
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    getUsersByFolder(folderId: string) {
        return HTTP.get(`/folder/affectedUsers?id=${folderId}`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    getFoldersByUser(userId: string) {
        return HTTP.get(`/folder/forUser?userId=${userId}`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    setFoldersByUser(userId: string, affectedFolders: string[]) {
        return HTTP.post(`/folder/affectUser`, { userId: userId, affectedFolders: affectedFolders })
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    addComment(folderId: string, content: string) {
        return HTTP.post(`/folder/comment`, { folderId: folderId, content: content })
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    getNotifications() {
        return HTTP.get(`/notification`, )
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    deleteNotification(notificationId: string) {
        return HTTP.delete(`/notification/${notificationId}`, )
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },
};
